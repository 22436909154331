@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "silkscreen";
  src: url('../assets/fonts/slkscrb.ttf');
}

.silkscreen {
  font-family: 'silkscreen';
}

.firasans {
  font-family: -apple-system, BlinkMacSystemFont, "Fira Sans";
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "IBM Plex Sans", sans-serif !important;
  color: white !important;
  font-size: 14px !important;
  height: auto !important;
  min-height: 100vh !important;
  background: #00101C !important;
}

.active_ {
  color: #7754F8 !important;
}

.active1_ {
  color: #1890ff !important;
}

.show-menu {
  height: 206px;
  transition: height 0.5s;
}

.hide-menu {
  height: 0px;
  transition: height 0.5s;
}

.show-bg {
  display: block;
  transition: display 0.5s;
}

.hide-bg {
  display: none;
  transition: display 0.5s;
}

.liTop {
  top: calc(50% + 8px) !important;
}

.liH1 {
  left: 5px;
  bottom: calc(50% - 10px) !important;
  height: calc(50% + 2px) !important;
}

.timeUl li:first-child .liH1 {
  border-color: transparent !important;
}

.timeUl li:last-child .liH2 {
  border-color: transparent !important;
}

.liH2 {
  left: 5px;
  top: calc(50% + 20px) !important;
  height: calc(50% + 10px) !important;
}

.rodd {
  background: #08263A !important;
}

.reven {
  background: #001A2C !important;
}

.nrodd {
  background: rgba(0, 19, 33, 0.2) !important;
}

.nreven {
  background: rgba(31, 57, 77, 0.2) !important;
}

.elip {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.butbg {
  background: linear-gradient(92.04deg, #B741FF 0%, #5833E2 100%);
  border-radius: 30px;
}

.img_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.img_mask_setup {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.img_wrap:hover .img_mask {
  opacity: 1;
}

.img_wrap_setup:hover .img_mask_setup {
  opacity: 1;
}

.token .ant-upload.ant-upload-select-picture-card {
  margin-bottom: 0 !important;
}

.star_req::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

@primary-color: #7754F8;@text-color: #ffffff;@btn-disable-bg: rgba(123, 87, 250, 0.05);@btn-disable-color: rgba(123, 87, 250, 0.6);@btn-disable-border: rgba(123, 87, 250, 0.4);@tabs-hover-color: #FFFFFF;@tabs-active-color: #FFFFFF;@modal-header-bg: #052439;@modal-content-bg: #001A2C;@modal-close-color: rgba(255, 255, 255, 0.6);@modal-header-border-color-split: transparent;@heading-color: #FFFFFF;@modal-header-title-line-height: 18px;@notification-bg: #1A083E;@notification-padding-vertical: 20px;@select-dropdown-bg: rgba(1, 29, 50, 1);@select-border-color: #1F394D;@select-item-active-bg: rgba(119, 84, 248, 0.2);@select-item-selected-bg: rgba(119, 84, 248, 0.3);