.ant-progress-inner {
  background-color: #000102 !important;
}

.ant-switch {
  background-color: rgba(123, 87, 250, 0.2) !important;
}

.ant-switch-checked {
  background-color: #7b57fa !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

.ant-tabs-nav::before {
  border-bottom: 1px solid #1a083e !important;
}

.ant-modal-close-x {
  width: 51px !important;
  height: 51px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-modal-close:hover {
  color: white !important;
}

.anticon {
  vertical-align: 0.1em !important;
}

.ant-input {
  background-color: transparent !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #7754f8 !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: -47px !important;
  width: 32px !important;
  height: 32px !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: -47px !important;
  width: 32px !important;
  height: 32px !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: -30px !important;
}

.ant-carousel .slick-dots li button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  background: #7754f8 !important;
}

.ant-carousel .slick-dots li.slick-active {
  width: inherit !important;
}

.ant-carousel .slick-dots li {
  width: auto !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.ant-table {
  color: white !important;
  background: transparent !important;
}

.ant-table-tbody td {
  cursor: pointer !important;
}

.ant-table-thead > tr > th {
  background: rgba(1, 29, 50, 0.2) !important;
  border-top: 1px solid #1f394d !important;
  border-bottom: 1px solid #1f394d !important;
  // border-color: transparent !important;
}

.noTop .ant-table-thead > tr > th {
  background: rgba(1, 29, 50, 0.2) !important;
  border-top: none !important;
  border-bottom: 1px solid #1f394d !important;
}

.ant-table-tbody > tr > th {
  background: transparent !important;
}

.ant-table-tbody > tr > td {
  border-color: transparent !important;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: #001a2c !important;
}

.ant-empty-description {
  color: white !important;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0 !important;
}

.ant-table-tbody {
  > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background: rgba(31, 57, 77, 0.3) !important;
  }
}

.ant-table-fixed {
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background: #1e3545 !important;
  }
}

td.ant-table-column-sort {
  background: inherit !important;
}

.g2-tooltip-name,
.g2-tooltip-title {
  font-weight: bold !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #3c2886 !important;
  border-right: 3px solid #582df7 !important;
}

.ant-menu-item:hover {
  border-color: #582df7 !important;
}

.ant-select-selector {
  background: rgba(0, 19, 33, 0.2) !important;
  border-radius: 6px !important;
}

.ant-select-selector:hover {
  border-color: #7754f8 !important;
}

.ant-select:focus .ant-select-selector {
  border-color: #7754f8 !important;
}

.ant-select-focused .ant-select-selector {
  border-color: #7754f8 !important;
}

.ant-select-dropdown {
  border-radius: 6px !important; // 圆角
  overflow: hidden;
  padding: 0 !important;
}

.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-pagination-item-active {
  background: transparent !important;
}

.ant-pagination-disabled svg {
  color: #fff !important;
}

.ant-pagination-item-ellipsis {
  color: inherit !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #00192c !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #00192c !important;
}

.ant-badge-count {
  background: #6e48ea !important;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #7754f8 !important;
  opacity: 0.3 !important;
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: solid 1px #1f394d !important;
}

.menuselect {
  // .ant-select-selector {
  //   padding: 0 2px !important;
  // }

  .ant-select-arrow {
    color: white !important;
    // right: 10px !important;
  }
}

.ant-modal-content {
  border-radius: 12px 12px 12px 12px !important;
  background-color: rgba(1, 29, 50, 1) !important;
}

.ant-modal-header {
  background-color: rgba(1, 29, 50, 1) !important;
  border-radius: 12px 12px 0 0 !important;
  border-bottom: 1px solid #1f394d !important;
}

.action {
  .ant-modal-content {
    background-color: transparent !important;
  }
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: black !important;
}

.ant-picker-cell-in-view {
  color: black !important;
}

.ant-picker-content th {
  color: black !important;
}

.ant-picker-header {
  color: black !important;
}

.anticon-calendar svg {
  color: #fff !important;
}

.anticon-swap-right svg {
  color: #fff !important;
}

.ant-radio-inner:focus {
  outline-color: transparent !important;
}

.ant-message-notice-content {
  color: black !important;
}

// .ant-form-item-label>label::after {
//   content: '' !important;
// }

.ant-popover-inner {
  background-color: #000 !important;
}

.ant-popover-buttons {
  .ant-btn-default {
    color: #000 !important;
  }
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: #000 !important;
}

.ant-input[disabled] {
  color: white !important;
  opacity: 0.7 !important;
}

.ant-picker-disabled .ant-picker-input input {
  color: white !important;
  opacity: 0.7 !important;
}

.ant-select-disabled .ant-select-selection-item {
  color: white !important;
  opacity: 0.7 !important;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: rgba(31, 57, 77, 0.13) !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: none !important;
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-left: 0px !important;
}

.ant-upload.ant-upload-select-picture-card {
  width: 50px !important;
  height: 50px !important;
  background: transparent !important;
  border: 1px dashed #1f394d !important;
  border-radius: 100% !important;
}

.setup .ant-upload.ant-upload-select-picture-card {
  width: 100px !important;
  height: 50px !important;
  background: rgba(0, 19, 33, 0.2) !important;
  border: 1px solid #1f394d !important;
  border-radius: 6px !important;
}

.deploy-nft .ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  height: 200px !important;
  background: rgba(0, 19, 33, 0.2) !important;
  border: 1px dashed #1f394d !important;
  border-radius: 6px !important;

  &:hover {
    border-color: #7754f8 !important;
  }
}

.ant-collapse {
  border: 1px solid #1f394d !important;
  background-color: rgba(0, 19, 33, 0.2) !important;
}

.ant-collapse-content {
  background-color: rgba(1, 29, 50, 1) !important;
  border-top: 1px solid #1f394d !important;
}

.rewards {
  .ant-collapse {
    background-color: rgba(0, 19, 33, 0.8) !important;
  }

  .ant-collapse-content {
    background-color: rgba(0, 19, 33, 0.8) !important;
  }
}

.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative !important;
  padding: 5px 16px !important;
  padding-right: 40px !important;
}

.ant-select-multiple .ant-select-selection-item {
  background: rgba(119, 84, 248, 0.4) !important;
  border-color: rgba(119, 84, 248, 0.7) !important;
}

.anticon-close svg {
  color: white !important;
}

.ant-select-suffix svg {
  color: white !important;
}

.rmbor .ant-select-selector {
  border: none !important;
}

.rmbor .ant-select-focused .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.ant-select-clear {
  background: transparent !important;
}

.ant-select-clear svg {
  color: rgb(211, 206, 206) !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border-color: #ff4d4f !important;
}

.ant-typography {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}

.ant-collapse-item-disabled {
  .ant-collapse-header {
    cursor: default !important;
  }
}

.action {
  .ant-modal-content {
    display: inline-block;
  }
}
.ant-radio-inner {
  background-color: inherit !important;
  border-color: #1f394d !important;
  box-shadow: none !important;
  transition: none !important;
  &:hover {
    border-color: #7754f8 !important;
  }
}
.ant-radio:hover {
  .ant-radio-inner {
    border-color: #7754f8 !important;
  }
}
.ant-radio-checked {
  .ant-radio-inner {
    border-color: #7754f8 !important;
  }
}
.ant-checkbox-inner {
  background-color: inherit !important;
  border-color: #1f394d !important;
  transition: none !important;
}

.ant-checkbox:hover {
  .ant-checkbox-inner {
    border-color: #7754f8 !important;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #7754f8 !important;
    border-color: #7754f8 !important;
  }
}
.ant-input-suffix {
  .anticon-close-circle {
    svg {
      fill: #7754f8;
    }
  }
}
.ant-tabs-nav-list {
  .ant-tabs-tab {
    padding: 8px 26px 8px 0 !important;
    border: none !important;
    background: transparent !important;
  }
  .ant-tabs-nav-add {
    border: none !important;
    background: transparent !important;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #7754f8 !important;
    }
    .ant-tabs-tab-remove {
      .anticon-close {
        svg {
          fill: #7754f8 !important;
        }
      }
    }
  }
}
.ant-tabs-nav-add {
  border: none !important;
  background: transparent !important;
}
.ant-tabs-dropdown {
  .ant-tabs-dropdown-menu {
    background-color: rgba(1, 29, 50, 1) !important;
    .ant-tabs-dropdown-menu-item {
      &:hover {
        background: #f5f5f510 !important ;
      }
    }
  }
}

.ant-modal-content {
  .ant-modal-body {
    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-btns {
        .ant-btn-default {
          background: transparent !important;
        }
      }
    }
  }
}

.img-crop-control {
  .ant-slider {
    box-sizing: border-box;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    height: 12px;
    margin: 10px 6px;
    padding: 4px 0;
    cursor: pointer;
    touch-action: none;
    .ant-slider-rail {
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: #c9c5c5;
      transition: background-color 0.3s;
    }
    .ant-slider-track {
      position: absolute;
      height: 4px;
      background-color: #91d5ff;
      border-radius: 2px;
      transition: background-color 0.3s;
    }
    .ant-slider-step {
      position: absolute;
      width: 100%;
      height: 4px;
      background: 0 0;
      pointer-events: none;
    }
    .ant-slider-handle {
      position: absolute;
      width: 14px;
      height: 14px;
      margin-top: -5px;
      background-color: #fff;
      border: solid 2px #91d5ff;
      border-radius: 50%;
      box-shadow: 0;
      cursor: pointer;
      transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }
  }
}
.img-crop-modal {
  .ant-modal-footer {
    border-color: #1f394d !important;
    .ant-btn-default {
      color: #7754f8;
    }
  }
}

.ant-popover-inner {
  background-color: #1e3545 !important;
  .ant-popover-inner-content {
    .ant-btn-default {
      background: #7754f840 !important;
      color: #ffffff !important;
      border: none;
    }
  }
}
.ant-popover-arrow {
  .ant-popover-arrow-content {
    --antd-arrow-background-color: #1e3545 !important;
  }
}
.ant-modal-content {
  .ant-modal-footer {
    border-color: #1f394d !important;
    .ant-btn-default {
      color: #7754f8;
      border-color: #7754f8 !important;
    }
  }
}

.ant-image-preview-img {
  margin: auto !important;
}

.ant-input-affix-wrapper {
  background-color: transparent !important;
}
.ant-image-preview-img-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@primary-color: #7754F8;@text-color: #ffffff;@btn-disable-bg: rgba(123, 87, 250, 0.05);@btn-disable-color: rgba(123, 87, 250, 0.6);@btn-disable-border: rgba(123, 87, 250, 0.4);@tabs-hover-color: #FFFFFF;@tabs-active-color: #FFFFFF;@modal-header-bg: #052439;@modal-content-bg: #001A2C;@modal-close-color: rgba(255, 255, 255, 0.6);@modal-header-border-color-split: transparent;@heading-color: #FFFFFF;@modal-header-title-line-height: 18px;@notification-bg: #1A083E;@notification-padding-vertical: 20px;@select-dropdown-bg: rgba(1, 29, 50, 1);@select-border-color: #1F394D;@select-item-active-bg: rgba(119, 84, 248, 0.2);@select-item-selected-bg: rgba(119, 84, 248, 0.3);