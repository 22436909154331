.cm-s-dracula.CodeMirror, .cm-s-dracula .CodeMirror-gutters {
  background-color: #010E18 !important;
  border-radius: 6px !important;
}
.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  padding-left: 12px !important;
}
.CodeMirror-scroll {
  padding-top: 6px !important;
}
.CodeMirror-placeholder{
  color: white !important;
  opacity: 0.6;
}
@primary-color: #7754F8;@text-color: #ffffff;@btn-disable-bg: rgba(123, 87, 250, 0.05);@btn-disable-color: rgba(123, 87, 250, 0.6);@btn-disable-border: rgba(123, 87, 250, 0.4);@tabs-hover-color: #FFFFFF;@tabs-active-color: #FFFFFF;@modal-header-bg: #052439;@modal-content-bg: #001A2C;@modal-close-color: rgba(255, 255, 255, 0.6);@modal-header-border-color-split: transparent;@heading-color: #FFFFFF;@modal-header-title-line-height: 18px;@notification-bg: #1A083E;@notification-padding-vertical: 20px;@select-dropdown-bg: rgba(1, 29, 50, 1);@select-border-color: #1F394D;@select-item-active-bg: rgba(119, 84, 248, 0.2);@select-item-selected-bg: rgba(119, 84, 248, 0.3);